import clsx from 'clsx';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import * as classes from './UserTypeSelector.module.scss';

const UserTypeSelector = ({ value, onChange }) => {
    const { t } = useTranslation();

    return <div className={classes.UserTypeSelector}>
        <div className={clsx(classes.Left, value === 'individual' && classes.Selected)}
             onClick={() => onChange('individual')}
             onKeyUp={() => onChange('individual')}
        >
            {t('individual')}
        </div>
        <div className={clsx(classes.Right, value === 'business' && classes.Selected)}
             onClick={() => onChange('business')}
             onKeyUp={() => onChange('business')}
        >
            {t('business')}
        </div>
        <div
            className={clsx(classes.Selector, value === 'individual' && classes.Individual, value === 'business' && classes.Business)}></div>
    </div>;
};

UserTypeSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOf([ 'individual', 'business' ]).isRequired
};

export default UserTypeSelector;
