// extracted by mini-css-extract-plugin
export var AppGallery = "pricing-module--AppGallery--6321a";
export var AppStore = "pricing-module--AppStore--f412b";
export var AwsMarketplace = "pricing-module--AwsMarketplace--98457";
export var Banner = "pricing-module--Banner--c195b";
export var Billing = "pricing-module--Billing--4e7df";
export var BillingSelectorContainer = "pricing-module--BillingSelectorContainer--1ecb2";
export var Border = "pricing-module--Border--30b04";
export var Business = "pricing-module--Business--10dcc";
export var Entry = "pricing-module--Entry--0d4b7";
export var Feature = "pricing-module--Feature--a1f19";
export var FeatureSpacer = "pricing-module--FeatureSpacer--8b32b";
export var Head = "pricing-module--Head--d22ce";
export var Header = "pricing-module--Header--b7836";
export var Item = "pricing-module--Item--86178";
export var Limits = "pricing-module--Limits--cc52a";
export var List = "pricing-module--List--d7a5c";
export var Main = "pricing-module--Main--a6ff5";
export var Message = "pricing-module--Message--1581c";
export var Muted = "pricing-module--Muted--cd280";
export var Notes = "pricing-module--Notes--9d986";
export var Plan = "pricing-module--Plan--e3669";
export var PlanResponsive = "pricing-module--PlanResponsive--62c30";
export var Plans = "pricing-module--Plans--3904e";
export var PlansContainer = "pricing-module--PlansContainer--5475c";
export var PlansContent = "pricing-module--PlansContent--d87cf";
export var Price = "pricing-module--Price--51604";
export var SelectorContainer = "pricing-module--SelectorContainer--be759";
export var Shown = "pricing-module--Shown--a8ed6";
export var Stores = "pricing-module--Stores--4ac77";
export var StoresTitle = "pricing-module--StoresTitle--a5e69";
export var Subtitle = "pricing-module--Subtitle--f541f";
export var Suggested = "pricing-module--Suggested--73136";
export var SuggestedTitle = "pricing-module--SuggestedTitle--88b00";
export var Support = "pricing-module--Support--02fa2";
export var Title = "pricing-module--Title--a3e19";
export var Toggle = "pricing-module--Toggle--7ea4c";
export var Top = "pricing-module--Top--4df33";