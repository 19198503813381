import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { report_app_store_click } from '../../shared/utils';
import { OutboundLink } from '../UI/OutboundLink/OutboundLink';
import * as classes from './AppGalleryLogo.module.scss';

const AppGalleryLogo = () => {
    return <OutboundLink href="https://s.tclab.it/xag-aida"
                         title="AIDA on Xerox App Gallery"
                         target="_blank" onClick={() => report_app_store_click('Xerox App Gallery')}
                         rel="noopener">
        <div className={classes.AppGalleryLogo}>
            <StaticImage
                src="../../images/tclab_xeroxAppGallery.png"
                alt="Xerox App Gallery logo"
                width={129}
                height={41}
                placeholder="none"
            />
        </div>
    </OutboundLink>;
};

export default AppGalleryLogo;
