import clsx from 'clsx';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import * as classes from './BillingToggle.module.scss';

const BillingToggle = ({ value, onChange }) => {
    const { t } = useTranslation();

    return (
        <div
            className={classes.Container}
            role="switch"
            tabIndex={0}
            aria-checked={!!value}
        >
            <span className={clsx(classes.Label, value === 'month' && classes.Selected)}
                  onClick={() => onChange('month')}>
                {t('pricing-page:Monthly')}
            </span>
            <span
                className={classes.ToggleBackground}
                onClick={() => onChange(value === 'month' ? 'year' : 'month')}
            >
                <div className={clsx(classes.Icon, value === 'month' ? classes.Left : classes.Right)} />
            </span>
            <span className={clsx(classes.Label, value === 'year' && classes.Selected)}
                  onClick={() => onChange('year')}>
                {t('pricing-page:Annually')}
                <div className={classes.Small}>
                    ({t('pricing-page:save')}*)
                </div>
            </span>
        </div>
    );
};

BillingToggle.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOf([ 'year', 'month' ]).isRequired
};

export default BillingToggle;
