import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import LANGUAGES from '../../../languages';
import { report_app_store_click, URLS } from '../../shared/utils';
import { OutboundLink } from '../UI/OutboundLink/OutboundLink';

const AppStoreLogo = () => {
    const { language } = useI18next();

    let logoLanguage = 'en';
    if (LANGUAGES.langs.indexOf(language) >= 0) {
        logoLanguage = language;
    }

    return <OutboundLink className="appStore" href={URLS.APPLE_APP_STORE_AIDA_MOBILE}
                         onClick={() => report_app_store_click('Apple App Store')}
                         rel="noopener"
                         target="_blank">
        {logoLanguage === 'it' && <StaticImage src="logo_it.png" alt="Apple App Store" width={165} height={55} />}
        {logoLanguage === 'de' && <StaticImage src="logo_de.png" alt="Apple App Store" width={165} height={55} />}
        {logoLanguage === 'es' && <StaticImage src="logo_es.png" alt="Apple App Store" width={165} height={55} />}
        {logoLanguage === 'fr' && <StaticImage src="logo_fr.png" alt="Apple App Store" width={165} height={55} />}
        {logoLanguage === 'pt' && <StaticImage src="logo_pt.png" alt="Apple App Store" width={165} height={55} />}
        {logoLanguage === 'en' && <StaticImage src="logo_en.png" alt="Apple App Store" width={165} height={55} />}
    </OutboundLink>;
};

export default AppStoreLogo;
